$primary: #00d1b2;
$primary-invert: #ffffff;

html,
body,
#layout-wrapper {
  height: 100%; }

#layout-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column; }

#content-wrapper {
  flex: 1; }

nav {
  border-bottom: lightgray solid 0.1vmin; }

.content .taglist {
  list-style: none;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 1.5rem;
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  li {
    padding: 0 2rem 1rem 0;
    margin-bottom: 1.5rem;
    margin-top: 0; } }

.margin-top-0 {
  margin-top: 0 !important; }

$tablet: 768px;

@import "~bulma";

.navbar-burger:hover {
  background-color: unset; }

.logo {
  color: #098c8c;
  letter-spacing: 0.02em; }

.ohnohoney {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1; }

.has-shadow {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02); }

.is-aliceblue {
  background-color: aliceblue; }

.has-border-grey {
  border: 1px solid lightgrey; }

.table.is-narrow td, .table.is-narrow th {
  padding: 1em 1em; }

.is-backgroundless {
  background: none !important; }

.is-borderless {
  border: none !important; }

.is-min-half-height {
  min-height: 50vh; }

.is-tablecell {
  display: table-cell !important; }

.is-underline {
  text-decoration: underline; }

footer a {
  color: #476282;
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400; }

footer a:not(:last-child):after {
  content: "·";
  margin: 0 8px; }

.is-256x256 {
  width: 256px;
  height: 256px; }

.is-margin-auto {
  margin: auto; }
